import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SpinService } from './spin.service';

@Component({
    selector: 'app-spin',
    templateUrl: 'spin.component.html',
    styleUrls: ['./spin.component.css'],
    standalone: false
})
export class SpinComponent implements OnInit, OnDestroy {
  spin$: Subscription = new Subscription();

  show: boolean = false;

  constructor(private readonly spinService: SpinService) {}

  ngOnInit(): void {
    this.spin$ = this.spinService.getService().subscribe((show: boolean) => {
      setTimeout(() => {
        this.show = show;
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.spin$.unsubscribe();
  }
}
