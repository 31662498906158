/* eslint-disable @typescript-eslint/no-var-requires */
export const environment: any = {
  production: true,
  env: 'production',
  api: {
    host: 'https://status.erpclass.com.br',
    prefix: '/api/v1',
  },
  socket: {
    url: 'https://status.erpclass.com.br',
  },
  sentry: {
    dsn: 'https://c4f35565319f43dfb6df6027ffa8ae32@o228705.ingest.us.sentry.io/6576367',
  },
  build:
    'Version: ' +
    require('../../package.json').version +
    ' - Build Date: ' +
    new Date().toLocaleDateString('pt-BR') +
    ' ' +
    new Date().toLocaleTimeString('pt-BR'),
};
