import { APP_BASE_HREF, NgOptimizedImage, provideImgixLoader } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, inject, NgModule, provideAppInitializer, provideZoneChangeDetection } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreServicesModule, CoreSharedModule, CoreStoreModule } from '@app/core';
import { ErrorHandlerUser } from '@app/error';
import { SpinModule } from '@app/services';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { AppService } from './app.service';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, NgOptimizedImage, ROUTES, SpinModule, CoreServicesModule, CoreSharedModule, CoreStoreModule],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerUser,
    },
    provideAppInitializer(async () => inject(AppService).init()),
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi()),
    provideImgixLoader('https://cdn.erpclass.com.br'),
    provideZoneChangeDetection({ eventCoalescing: true }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
